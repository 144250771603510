<template>
	<div class="playlistCreate">

		<h1 class="text-center">Wähle deine Übungen aus.</h1>

		<div v-if="exercises.length > 0" class="playlistExercise">

			<div @click="openExerciseModal(category)" class="playlistExercise__item" v-for="category in mainCategories" :key="category.id">
				<div class="playlistExercise__item__icon">
					<img v-if="category.name=='Oberkörper'" src="@/assets/ic_32_exercises_oberkoerper.svg" alt="" />
					<img v-if="category.name=='Unterkörper'" src="@/assets/ic_32_exercises_unterkoerper.svg" alt="" />
					<img v-if="category.name=='Core'" src="@/assets/ic_32_exercises_core.svg" alt="" />
					<img v-if="category.name=='Fullbody'" src="@/assets/ic_32_exercises_fullbody.svg" alt="" />
					<img v-if="category.name=='Mobility'" src="@/assets/ic_32_exercises_mobility.svg" alt="" />
					<img v-if="category.name=='Warm Up'" src="@/assets/ic_32_exercises_warmup.svg" alt="" />
					<img v-if="category.name=='Cool Down'" src="@/assets/ic_32_exercises_cooldown.svg" alt="" />
				</div>
				<div class="playlistExercise__item__label">
					{{ category.name }}
				</div>
				<div class="playlistExercise__item__count" :class="category.selected_count ? 'playlistExercise__item__count--active' : ''">
					{{ category.selected_count || 0 }}
				</div>
			</div>

		</div>

		<v-btn :disabled="exercises.length == 0" class="button button--primary" @click="continuePlaylistCreate">Weiter</v-btn>

		<VModal name="exerciseSelect" width="100%" height="auto" :shiftY="1" :scrollable="true" v-if="currentCategory" class="exercise-modal">
			<div class="vm-header">
				<div class="vm-header-left">
					<img v-if="showSelectResetButton" src="@/assets/ic_nav_reset.svg" alt="" @click="resetExerciseSelection">
				</div>
				<div class="vm-header-center">
					{{ currentCategory.name }}
				</div>
				<div class="vm-header-right">
					<img src="@/assets/ic_nav_close.svg" alt="" @click="closeExerciseSelectWithoutSave">
				</div>
			</div>
			<div class="vm-content">
				<div v-for="exercise in currentExercises" :key="exercise.id" class="list">
					<div :class="exerciseClasses(exercise)">
						<div class="list__item__icon list__item__icon--image">
							<img :src="'https://app.sportlerplus.com' + exercise.square_preview_image_url" alt="PreviewImage">
						</div>
						<div v-if="exerciseAvailable(exercise)" class="list__item__content" @click="toggleExerciseSelect(exercise)">
							<div class="list__item__label">
								{{ exercise.title }}
							</div>
							<img v-if="exercise.requires_equipment && exercise.selected" src="@/assets/ic_equipment-active.svg" class="equipment-icon">
							<img v-else-if="exercise.requires_equipment && !exercise.selected" src="@/assets/ic_equipment.svg" class="equipment-icon">
							<img @click="previewExercise(exercise)" class="play-icon" src="@/assets/ic_24_play.svg">
						</div>
						<div v-else class="list__item__content">
							<div class="list__item__label">
								{{ exercise.title }}
							</div>
							<div class="exercise-modal__premium-badge">
								Premium
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="vm-footer">
				<v-btn @click="finalizeSelection" class="button button--primary button--sticky">Auswahl bestätigen</v-btn>
			</div>
		</VModal>

		<ModalRoute></ModalRoute>
	</div>
</template>

<script>
import ModalRoute from "@/components/ModalRoute.vue";

export default {
	name: 'PlaylistCreateName',
	components: { ModalRoute },
	data() {
		return {
			categories: [],
			exercises: [],
			currentCategory: null,
			currentExercises: [],
			previousExerciseSelection: [],
			playlistData: null,
			showSelectResetButton: false,
		}
	},
	computed: {
		mainCategories: function () {
			return this.categories.filter(i => i.parent == null && i.mix_available);
		},
		premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.categories = this.$store.getters.getCategories;
			this.playlistData = this.$store.getters.getTemporaryPlaylist;
			let premiumUser = Object.keys(this.premiumStatus).length !== 0;
			this.$store.dispatch('fetchExercises').then(() => {
				this.exercises = this.$store.getters.getExercises;
				this.clearSelectionCount();
				if (this.playlistData && this.playlistData.items) {
					this.playlistData.items.forEach(ex => {
						let tempExercise = this.exercises.find(i => i.id == ex.id);
						if (tempExercise.premium_content && !premiumUser) { return; }
						tempExercise.selected = true;
						tempExercise.sortOrder = ex.sortOrder;
					});
					this.calculateSelectionCount();
				}
				this.$forceUpdate();
			});
			this.currentCategory = this.mainCategories[0];
		},
		continuePlaylistCreate() {
			let playlistItems = this.exercises.filter(i => i.selected);
			playlistItems.sort( (a,b) => { return a.sortOrder - b.sortOrder; });
			this.$store.dispatch('updateTemporaryPlaylist', {
				items: playlistItems,
			});
			this.$router.push("/playlists/create/summary");
		},
		openExerciseModal(category) {
			this.currentCategory = category;
			let tempExercises = this.filterExercises(category);
			this.currentExercises = JSON.parse(JSON.stringify(tempExercises));
			this.showSelectResetButton = false;
			this.previousExerciseSelection = JSON.parse(JSON.stringify(this.currentExercises));
			this.$modal.show("exerciseSelect");
		},
		filterExercises(category) {
			let searchCategories = this.categories.filter(c => c.parent != null && c.parent.id == category.id).map(s => s.id);
			// add main category id
			searchCategories.push(category.id);

			let filtered = [];
			this.exercises.forEach(function(el) {
				let itemCategories = el.categories.map(ic => ic.id);
				if (itemCategories.filter(ic => searchCategories.includes(ic)).length > 0) {
					filtered.push(el);
				}
			});
			return filtered.sort((a,b) => a.title.localeCompare(b.title));
		},
		toggleExerciseSelect(exercise) {
			exercise.selected = !exercise.selected;
			this.showSelectResetButton = true;
			this.$forceUpdate();
		},
		previewExercise(exercise) {
			this.$router.push("/exercise/preview/" + exercise.id);
		},
		clearSelectionCount() {
			this.exercises.forEach(ex => ex.selected = false);
			this.categories.forEach(cat => cat.selected_count = 0 );
		},
		exerciseClasses(exercise) {
			let class_list = "list__item list__item--background-dark";
			if (exercise.selected) { class_list = class_list + " selected"; }
			if (exercise.premium_content && (Object.keys(this.premiumStatus).length === 0)) { class_list = class_list + " disabled"; }
			return class_list;
		},
		exerciseAvailable(exercise) {
			return !(exercise.premium_content && (Object.keys(this.premiumStatus).length === 0));
		},
		calculateSelectionCount() {
			this.categories.filter(cat => cat.parent == null).forEach(cat => {
				cat.selected_count = this.filterExercises(cat).filter(ex => ex.selected).length;
			});
		},
		finalizeSelection() {
			this.currentCategory.selected_count = this.currentExercises.filter(i => i.selected).length;
			this.currentExercises.forEach(cx => {
				this.exercises.find(e => e.id == cx.id).selected = cx.selected;
			})
			this.$modal.hide('exerciseSelect');
			this.calculateSelectionCount();
			this.$forceUpdate();
		},
		resetExerciseSelection() {
			this.currentExercises = JSON.parse(JSON.stringify(this.previousExerciseSelection));
			this.showSelectResetButton = false;
		},
		closeExerciseSelectWithoutSave() {
			this.$modal.hide('exerciseSelect');
		}
	}
}
</script>

<style lang="scss">
	/* TODO: REMOVE CODE IF SCSS FROM PLAYLISTCREATENAME EFFECTS GLOBALLY */
	.playlistCreate {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		h1 {
			margin-top: 31px;
			margin-bottom: 18px;
		}

		p {
			margin-bottom: 41px;
		}

		.button {
			margin-top: auto;
		}
	}

	.playlistExercise {
		margin-top: 36px;
		margin-bottom: 16px;

		&__item {
			display: flex;
			align-items: center;
			padding: 16px 20px 16px 16px;
			margin-bottom: 8px;
			background: $colorWhite;
			box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
			border-radius: 8px;
			text-decoration: none;
			cursor: pointer;

			&__icon {
				display: flex;
				margin-right: 16px;

				img {
					width: 32px;
					height: 32px;
				}
			}

			&__label {
				font-size: 17px;
				font-weight: 600;
				letter-spacing: -0.25px;
				line-height: 24px;
				color: $colorBlack;
			}

			&__count {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 3px;
				min-width: 24px;
				height: 24px;
				padding: 7px 0;
				margin-left: auto;
				background: rgba($colorBlack, .04);
				font-size: 14px;
				font-weight: 600;
				letter-spacing: -0.21px;
				line-height: 20px;
				color: rgba($colorBlack, .24);

				&--active {
					color: $colorWhite;
					background: $colorPrimary;
				}
			}
		}
	}

	.exercise-modal {
		.equipment-icon {
			margin: -7px 0px -7px 7px;
		}
		.play-icon {
			padding: 8px;
			margin: -10px -8px -10px auto;
			z-index: 9;
		}
		.list__item {
			width: 100%;
			max-width: 480px;
			margin: 0px auto;

			&__content {
				overflow: hidden;

				&:after {
					display: none !important;
				}
			}

			&__label {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		&__premium-badge {
			display: flex;
			background: $colorPrimary;
			color: $colorWhite;
			font-size: 11px;
			line-height: 18px;
			height: 18px;
			font-weight: 700;
			text-transform: uppercase;
			padding: 0 4px;
			border-radius: 2px;
			margin: 1px 0 0 auto;
		}
	}
</style>